.keystone-file-manager {

}


.file-label {
    text-decoration: underline;
    cursor: pointer;
}

#replace-file-button {
    text-decoration: none !important;
}

p > .file-label > button {
    text-decoration: none !important;
}

.cta-drop-wrapper {
    border: 2px dashed var(--ks-brand-color);
    padding: 8px;
    border-radius: 8px;
}

.cta-drop-wrapper.cta .drop-zone {
    /* background-color: var(--ks-brand-color-light); */
    background-color: var(--ks-brand-color);
    border: none;
}


.drop-zone {
    background-color: var(--ks-brand-color);
    box-sizing: border-box;
    border-radius: 8px;
    transition: all 0.2s ease-in-out;
}

.drop-zone.hover {
    background: var(--ks-brand-color);
}

@media only screen and (max-width: 767px) {
    .cta-drop-wrapper {
        border: none;
        padding: 0px;
        border-radius: 8px;
    }

    .drop-zone {
        background-color: white;
        padding: 0
    }

    #file-upload-input-wrapper {
        background-color: var(--ks-brand-color);
        text-align: center;
        justify-content: center;
        border-radius: 4px;
        padding: 8px;
        text-decoration: none;
        font-weight: 600;
    }

    .cta-drop-wrapper p.secondary.on-brand {
        color: var(--ks-blue-dark);
    }

    .cta-drop-wrapper.cta .drop-zone {
        background-color: transparent;
    }
}